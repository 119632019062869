<template>
  <div class="gallery-post-page">
    <div v-if="post">
      <div v-if="post" class="post-container">
        <v-row justify="center">
          <v-col md="6">
            <!-- image link -->
            <v-img
              v-if="post.image_file"
              :src="post.image_file.document"
              contain
              width="100%"
              :max-height="$vuetify.breakpoint.mdAndUp ? '80vh' : ''"
            >
              <template v-slot:placeholder>
                <v-skeleton-loader
                  type="list-item-avatar, list-item-three-line"
                >
                </v-skeleton-loader>
              </template>
              <div class="image-icon"></div>
            </v-img>
            <!-- Video -->
            <custom-video-player     
              v-else-if="post.video_file"
              :visible="true"
              :link="post.video_file.document"
            />
            <!-- video link -->
            <div v-else-if="post.video_link">
              <v-responsive :aspect-ratio="16 / 9">
                <iframe
                  width="100%"
                  height="100%"
                  class="youtube-frame"
                  :src="`${ytLink}?showinfo=0&autoplay=1&rel=0&modestbranding=1`"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-responsive>
            </div>

            <div class="text-left my-2 mx-2">
              <p class="text-h6 my-0 py-0"
              v-html="TextHelper.linkify(post.caption)"
              >
              </p>
              <p class="text-caption my-0 py-0">
                {{ moment(post.created).format("ll") }}
              </p>
            </div>

            <div class='mx-3' v-if="post.image_file">
              <v-btn 
                color='primary' 
                block 
                @click="downloadImg(post.image_file.document, post.image_file.file_name)">
                  <v-icon>mdi-download</v-icon> Download
              </v-btn>
            </div>

          </v-col>
        </v-row>
      </div>
    </div>
    <!-- delete dialog -->
    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this gallery post?"
      description="This action cannot be undone"
      @successCallback="deleteGalleryPost"
      @failureCallback="showDeleteDialog = false"
      delete-dialog
    />
    <!-- edit image post -->
    <intract-create-edit-sheet
      persistent
      key="gallery-edit-image-sheet"
      class="gallery-edit-image-sheet"
      title="Edit Image Post"
      description="Upload image(s) and display in gallery"
      :visible="editImagePostSheet.visible"
      @close="
        editImagePostSheet.visible = false;
        editImagePostSheet.editId = null;
      "
      :fields="editImagePostFormFields"
      :file-size-limit="5242880"
      :endpoint="endpoints.galleryPostViewSet"
      :edit-id="editImagePostSheet.editId"
      :data-object="editImagePostSheet.obj"
      :edit-success-message="editImagePostSheet.editSuccessMessage"
      enable-media
      @objectCreated="onRefresh"
      @updateObject="(obj) => (editImagePostSheet.obj = obj)"
    />
    <!-- edit video post -->
    <intract-create-edit-sheet
      persistent
      key="gallery-edit-video-sheet"
      class="gallery-edit-video-sheet"
      title="Edit Video Post"
      description="Upload video content to the gallery"
      :visible="editVideoPostSheet.visible"
      @close="
        editVideoPostSheet.visible = false;
        editVideoPostSheet.editId = null;
      "
      :fields="editVideoPostFormFields"
      :file-size-limit="5242880"
      :endpoint="endpoints.galleryPostViewSet"
      :edit-id="editVideoPostSheet.editId"
      :data-object="editVideoPostSheet.obj"
      :create-success-message="editVideoPostSheet.editSuccessMessage"
      :enable-media="!(editVideoPostSheet.obj.video_link || editVideoPostSheet.obj.video_file)"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (editVideoPostSheet.obj = obj)"
      :afterEditObjectReceived="afterVideoObjectReceived"
    />
  </div>
</template>
<script>
import TextHelper from '@utils/text_helper';
import Mixins from "@utils/mixins";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import moment from "moment";
import CustomVideoPlayer from"@components/base/CustomVideoPlayer"
import EventBus from "@utils/event_bus";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
export default {
  name: "GalleryPost",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  components: {
    ConfirmationDialog,
    IntractCreateEditSheet,
    CustomVideoPlayer,
  },
  data() {
    return {
      TextHelper,
      post: null,
      showDeleteDialog: false,
      moment,
      editImagePostSheet: {
        visible: false,
        editSuccessMessage: "Post edited successfully!",
        editId: null,
        obj: {
          created_by: null,
          institution: null,
          is_folder: false,
        },
      },
      editVideoPostSheet: {
        visible: false,
        editSuccessMessage: "Post edited successfully!",
        editId: null,
        obj: {
          created_by: null,
          institution: null,
          is_folder: false,
        },
      },
    };
  },
  computed: {
    editImagePostFormFields() {
      return {
        caption: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Caption",
          required: false,
          md: 12,
          max: 500,
        },
        image_file: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: false,
          label: "Image",
          helper: "JPG, PNG, GIF, etc",
          valid: () => this.editImagePostSheet.obj.image_file != null,
        },
      };
    },
    editVideoPostFormFields() {
      return {
        caption: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Caption",
          required: false,
          md: 12,
          max: 500,
        },
        video_link: {
          fieldType: CreateEditFieldTypes.LINKUPLOAD,
          multiple: false,
          label: "Video Link",
          helper: "Link a YouTube Video",
          valid: () => (this.editVideoPostSheet.obj.video_file != null) || (this.editVideoPostSheet.obj.video_link != null),
        },
        video_file: {
          fieldType: CreateEditFieldTypes.VIDEOUPLOAD,
          multiple: false,
          label: "Upload a Video",
          helper: "Upload a video from device",
          valid: () => (this.editVideoPostSheet.obj.video_file != null) || (this.editVideoPostSheet.obj.video_link != null),
        },
      };
    },
    ytLink() {
      if (!this.post) return "";
      var link = this.post.video_link;
      link = link.replace(/shorts\//g, '');
      link = link.replace(/youtube.com/g, 'youtu.be');
      console.log(link);
      var VID_REGEX =
        /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      var videoId = link.match(VID_REGEX)[1];
      return `https://youtube.com/embed/${videoId}/`;
    },

    appBarOptions() {
      if (!this.currentUser.is_admin) return { actions: [] };
      return {
        actions: [
          {
            id: 1,
            title: "Edit",
            icon: "mdi-pencil",
            onClick: () => {
              if (this.post.image_file) {
                this.editImagePostSheet.editId = Number(
                  this.$route.params.postId
                );
                this.editImagePostSheet.visible = true;
              } else if (this.post.video_link || this.post.video_file) {
                this.editVideoPostSheet.visible = true;
                this.editVideoPostSheet.editId = Number(
                  this.$route.params.postId
                );
              }
              // this..visible = true;
              // this.introSheet.editId = Number(this.$route.params.introId);
            },
          },
          {
            id: 2,
            title: "Delete",
            icon: "mdi-delete",
            onClick: () => (this.showDeleteDialog = true),
          },
        ],
      };
    },
  },
  methods: {
    async getGalleryPost() {
      var url =
        this.endpoints.galleryPostViewSet + this.$route.params.postId + "/";
      this.post = await this.api.call(this.essentials, url);
      EventBus.$emit(
        this.post.parent
          ? "gallery__gallery_folder__post_updated"
          : "gallery__post_updated",
        this.post
      );
    },

    async deleteGalleryPost() {
      var url =
        this.endpoints.galleryPostViewSet + this.$route.params.postId + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Gallery post has been successfully deleted!",
          type: "success",
        });
        EventBus.$emit(
          this.post.parent
            ? "gallery__gallery_folder__post_deleted"
            : "gallery__post_deleted",
          this.$route.params.postId
        );
        this.$router.back();
      }
    },

    async afterVideoObjectReceived(obj) {
      if (!obj.video_file){
        obj.video_link = { link: obj.video_link };
      }
      return obj;
    },

    async onRefresh() {
      this.getGalleryPost();
    },

    async downloadImg(url, label){
      const downloadEssentials = {
        id: this.Helper.generateUID(),
        progres: null,
        file_name: label,
        featureName: "Gallery",
      };
      await this.api.download(
        this,
        this.essentials,
        url,
        downloadEssentials,
        "downloads/"
      )

      this.showSnackbar({
        title: "Image saved to Gallery!",
        type: "success",
      })

      /* const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', label);
      document.body.appendChild(link);
      link.click(); */
      //document.body.removeChild(link);
    }
  },
  created() {
    this.getGalleryPost();
    this.editImagePostSheet.obj.institution = this.currentInstitution.id;
    this.editImagePostSheet.obj.created_by = this.currentUser.id;
    this.editVideoPostSheet.obj.institution = this.currentInstitution.id;
    this.editVideoPostSheet.obj.created_by = this.currentUser.id;
    // setTimeout(() => {
      // document.getElementsByTagName('iframe')[0].contentWindow.getElementsByClassName('ytp-watch-later-button')[0].style.display = 'none';
    // }, 2000);
  },
};
</script>
<style lang="sass" scoped>
.post-container
  position: absolute
  right: 0
  left: 0
  margin-left: auto
  margin-right: auto
  text-align: center
  max-height: 100%
.image-icon
  height: 100%
  width: 100%
  display: flex
  justify-content: center
  align-items: center
</style>
